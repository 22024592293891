import { useEffect } from 'react';

const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    function initializeHubspotForm() {
        if ('hbspt' in window) {
            // Just to make sure the script is loaded (you can also do it by attaching an `onload` event to the script but I did it in this way
            window.hbspt.forms.create({
                portalId: "8869184",
                formId: "17018eae-8eb4-45f1-9697-1563bb04014a",
                target: "#signupForm"
            });
            
        } else {
            setTimeout(initializeHubspotForm, 500)
        }
    }
    initializeHubspotForm()
    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

export default useScript;